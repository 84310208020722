<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  components: {
    
  }
}
</script>
<style lang="sass">
.success-notify-icon
    color: #198cf0
</style>
import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import simplebar from "simplebar-vue";
import VueTour from 'vue-tour'
import vco from "v-click-outside"
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from './i18n'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import router from './router'
import store from '@/state/store'
import VueApexCharts from 'vue-apexcharts'
import VueStringFilter from 'vue-string-filter'
import Lightbox from 'vue-easy-lightbox'
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

Vue.config.productionTip = false

Vue.use(CKEditor)
Vue.use(vco)

// As a plugin
import VueMask from 'v-mask'
import VueDraggable from "vue-draggable";
import Select2 from 'v-select2-component';

Vue.component('Select2', Select2);
Vue.use(VueDraggable);
Vue.use(VueMask);

import VueSlideBar from 'vue-slide-bar'
Vue.component('VueSlideBar', VueSlideBar)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.component('apexchart', VueApexCharts)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(require('vue-chartist'))
Vue.component('simplebar', simplebar)
Vue.use(VueStringFilter)
Vue.use(VueTour)
Vue.use(Lightbox)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});

// 處理需要引用的元件
import Apis from './library/Apis'
import Common from './library/Common'

// 處理引入的 CSS
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import "@/assets/scss/app.scss";
import '@/assets/css/def.css'

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  data: {
    apis: false,
    common: false,
    dashboard_uri: process.env.VUE_APP_DASHBOARD_SITE_URL,
    website_uri: process.env.VUE_APP_WEB_SITE_URL,
    upload_uri: process.env.VUE_APP_UPLOAD_SERVER_URI,
    system_ready: false,
    apis: false,
    common: false,
    auth_token: false,
    login_status: false,
    user: { id: false },
    newsTypeList: []
  },
  created () {
    // 初始化後台系統
    this.initSystem()
  },
  watch: {
    // 當後台系統準備完成時
    system_ready (_systemReadyStatus) {
      if (!_systemReadyStatus) {
        return
      }

      // 如果登入狀態無效, 就丟去登入頁
      if (!this.login_status) {
        console.log('=> 引導至登入頁')
        return this.$router.push({ name: 'login', params: {} })
      }

      return
    }
  },
  methods: {
    // 初始化後台系統
    async initSystem () {
      // 初始化元件
      this.apis = await new Apis(this)
      this.common = await new Common(this)
      // 先獲取目前存有的 Token
      this.auth_token = await this.common.getCookie('api-token')
      // 處理 system token
      await this.apis.confirmToken()
    },
  },
}).$mount('#app')

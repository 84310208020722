export default [
    // 登入頁面
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login')
    },
    // 檔案設定
    {
        path: '/',
        name: 'fileSetting',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/FileSetting')
    },
    // 檔案設定內頁
    {
        path: '/file-edit/:fileId',
        name: 'fileEdit',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/FileEdit')
    }

]
import Vue from 'vue'
import VueResource from 'vue-resource'
import VueCookies from 'vue-cookies'
Vue.use(VueResource)
Vue.use(VueCookies)

var vm, project
// ================
Vue.http.options.root = process.env.VUE_APP_DAPI_URL + "/"
Vue.http.interceptors.push(function (_request, _next) {
    Vue.http.headers.common['Authorization'] = 'Bearer ' + Vue.$cookies.get('api-token')
    Vue.http.headers.common['Content-Type'] = 'application/json'

    // 處理一般程序
    _next(function (_response) {
        var data = _response.body
        // console.log('執行錯誤檢查: ' + data.error_code)
        if (data) {
            switch (data.code) {
                case "1080":
                    // 彈出警告視窗
                    project.common.blockAction()
                    break
                default:
                    break
            }
        }
    })
})

export default class Apis {
    constructor(_vm) {
        vm = this
        project = _vm
    }

    /**
     * 請求確認目前裝置 Token 的狀態
     * @param {*} _success 
     * @param {*} _error 
     * @returns 
     */
    async confirmToken (_success, _error) {
        if (!project.auth_token) {
            return this.issueToken()
        }

        this.getTokenDetail(_success, function (_response) {
            // 准予可以自行更換 Token 的錯誤
            if (["1078", "1079"].indexOf(_response.body.code) > -1) {
                vm.issueToken()
            }
        })
    }

    /**
     * 請求獲取新的 Token
     * @param {*} _success 
     * @param {*} _error 
     */
    async issueToken (_success, _error) {
        await Vue.http.get("token").then(function (_response) {
            console.log("=> 取得新的 Token: " + _response.body.data);
            project.common.setCookie("api-token", _response.body.data)
            vm.getTokenDetail()
        }, _error)
    }

    /**
     * 取得目前 Token 相關資訊
     * @param {*} _success 
     * @param {*} _error 
     */
    async getTokenDetail (_success, _error) {
        await Vue.http.get("token/detail").then(function (_response) {
            let response = _response.body.data

            // 如果已經登入
            if (response.bind) {
                vm.getMemberInfo()
            }

            // 如果 Token 被綁定中, 且並未遭到封鎖
            project.login_status = (!response.block && response.bind) ? true : false
            // 設定後台系統啟用完成
            project.system_ready = true
        }, _error)
    }

    /**
     * 獲取登入的會員資料
     * @param {*} _success 
     * @param {*} _error 
     */
    async getMemberInfo (_success, _error) {
        await Vue.http.get("member/status").then(function (_response) {
            project.user = _response.body.data

            if (project.needGoBackPage) {
                project.needGoBackPage = false
                // return project.goLastPage()
            }

            if (project.needAutoRedirect) {
                project.needAutoRedirect = false
                // return project.goPage('home')
            }

        }, _error)
    }

    /**
     * 向後端請求進行登入操作
     * @param {*} _form 
     * @param {*} _success 
     * @param {*} _error 
     */
    async setMemberLogin (_form, _success, _error) {
        await Vue.http.post('member/login', _form).then(_success, _error)
    }

    /**
     * 向後端請求進行登出操作
     * @param {*} _success 
     * @param {*} _error 
     */
    async setMemberLogout (_success, _error) {
        await Vue.http.post('member/logout').then(_success, _error)
    }

    /**
     * 獲得檔案清單
     * @param {*} _success 
     * @param {*} _error 
     */
    async getFileList (_search, _success, _error) {
        await Vue.http.get('files/list', { params: _search }).then(_success, _error)
    }

    /**
     * 取得單一檔案
     * @param {*} _FileId 
     * @param {*} _success 
     * @param {*} _error 
     */
    async getFileItem (_FileId, _success, _error) {
        await Vue.http.get('files/item/' + _FileId).then(_success, _error)
    }

    /**
     * 建立單一檔案
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async createFileItem (_data, _success, _error) {
        await Vue.http.post('files/item', _data).then(_success, _error)
    }

    /**
     * 更新單一檔案
     * @param {*} _FileId 
     * @param {*} _data 
     * @param {*} _success 
     * @param {*} _error 
     */
    async putFileItem (_FileId, _data, _success, _error) {
        await Vue.http.put('files/item/' + _FileId, _data).then(_success, _error)
    }
}